import { render, staticRenderFns } from "./orderForm.vue?vue&type=template&id=70dc4711&scoped=true"
import script from "./orderForm.vue?vue&type=script&lang=js"
export * from "./orderForm.vue?vue&type=script&lang=js"
import style0 from "./orderForm.vue?vue&type=style&index=0&id=70dc4711&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70dc4711",
  null
  
)

export default component.exports